var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 font-size-18 text-white"},[_vm._v(_vm._s(_vm.course.title))])])])]),(_vm.course.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"ml-2 align-middle",attrs:{"small":"","variant":"white","role":"status"}})],1):_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-10"},[_c('div',{staticClass:"course mb-4"},[_c('div',{staticClass:"course-plataform"},[_c('div',{staticClass:"course-player"},[(_vm.lesson.plataform.company == 'youtube')?_c('div',[_c('iframe',{staticClass:"rounded",attrs:{"width":"560","height":"315","src":'https://www.youtube.com/embed/' +
                  _vm.lesson.plataform.code +
                  '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1',"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):(_vm.lesson.plataform.company == 'vimeo')?_c('div',[_c('iframe',{attrs:{"title":"vimeo-player","src":'https://player.vimeo.com/video/' +
                  _vm.lesson.plataform.code.split('/')[0] +
                  '?h=' +
                  _vm.lesson.plataform.code.split('/')[1],"width":"640","height":"360","frameborder":"0","allowfullscreen":""}})]):(_vm.lesson.plataform.company == 'pandavideo')?_c('div',[_c('iframe',{staticStyle:{"border":"none"},attrs:{"id":"panda-64457e36-2e30-43f7-bea8-97947193d028","src":'https://player-vz-8814550d-d06.tv.pandavideo.com.br/embed/?v=' +
                  _vm.lesson.plataform.code,"allow":"accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture","allowfullscreen":"true","width":"546","height":"320","fetchpriority":"high"}})]):_vm._e()])]),_c('div',{staticClass:"course-module"},[_c('div',{staticClass:"module-display"},[_c('div',{staticClass:"overflow-auto"},[(_vm.modules.length == 0)?_c('div',{staticClass:"p-4 text-center"},[_vm._v(" Nenhum módulo encontrado. ")]):_vm._l((_vm.modules.list),function(m,id){return _c('b-card',{key:id,staticClass:"m-0",attrs:{"no-body":""}},[_c('div',{staticClass:"lesson-header rounded p-0",attrs:{"role":"tab"}},[_c('button',{staticClass:"bg-dark text-white d-flex justify-content-between w-100 border-0 p-3 m-0 text-left",on:{"click":function($event){return _vm.setModule(m.slug)}}},[_c('div',{staticClass:"sequence align-self-center"},[_c('div',{staticClass:"rounded-circle bg-white text-dark text-center"},[_vm._v(" "+_vm._s(id + 1)+" ")])]),_c('div',{staticClass:"title flex-fill px-3"},[_c('h6',{staticClass:"m-0 text-white"},[_vm._v(_vm._s(m.title))]),_c('small',[_vm._v(_vm._s(m.lessons.length)+" aulas")])]),_c('div',{staticClass:"arrow align-self-center pr-2"},[(m.slug == _vm.modules.slug)?_c('img',{staticClass:"down",attrs:{"src":require("@/assets/images/arrow-down.svg")}}):_c('img',{staticClass:"up",attrs:{"src":require("@/assets/images/arrow-up.svg")}})])])]),_c('b-collapse',{attrs:{"id":m.slug,"visible":m.slug == _vm.modules.slug,"accordion":"modules"}},[_c('b-card-body',{staticClass:"bg-dark lesson-body pt-2 px-4"},_vm._l((m.lessons),function(l,index){return _c('div',{key:index,staticClass:"lesson-line m-0 pt-3"},[(
                            m.slug == _vm.modules.slug && l.slug == _vm.lesson.slug
                          )?_c('div',{staticClass:"d-flex lesson-link"},[_c('div',{staticClass:"align-self-center lesson-circle-active pr-3",class:[
                              { first: index == 0 },
                              { last: m.lessons.length == index + 1 },
                            ]},[_c('button')]),_c('div',{staticClass:"align-self-center lesson-title"},[_c('router-link',{staticClass:"text-white font-weight-bold",attrs:{"tag":"a","to":'/courses/' +
                                _vm.course.slug +
                                '/' +
                                m.slug +
                                '/' +
                                l.slug}},[_vm._v(_vm._s(l.title))])],1)]):_c('div',{staticClass:"d-flex lesson-link"},[_c('div',{staticClass:"align-self-center lesson-circle-inactive pr-3",class:[
                              { first: index == 0 },
                              { last: m.lessons.length == index + 1 },
                            ]},[_c('button')]),_c('div',{staticClass:"align-self-center lesson-title"},[_c('router-link',{staticClass:"text-white",attrs:{"tag":"a","to":'/courses/' +
                                _vm.course.slug +
                                '/' +
                                m.slug +
                                '/' +
                                l.slug}},[_vm._v(_vm._s(l.title))])],1)])])}),0)],1)],1)})],2)])])]),_c('div',{staticClass:"course-lesson"},[_c('div',{staticClass:"card bg-dark p-2"},[_c('div',{staticClass:"card-body p-4"},[_c('h4',{staticClass:"text-white mb-4"},[_vm._v(_vm._s(_vm.lesson.title))]),_c('div',{staticClass:"description pt-2 text-white"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.lesson.description)}})])])])]),_c('div',{staticClass:"course-comments"},[_c('div',{staticClass:"card bg-dark p-2"},[_c('div',{staticClass:"card-body p-4"},[_c('h4',{staticClass:"text-white mb-4"},[_vm._v("Comentários")]),_c('div',{staticClass:"description pt-2 text-white"})])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }